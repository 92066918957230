exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-symmetry-js": () => import("./../../../src/pages/about-symmetry.js" /* webpackChunkName: "component---src-pages-about-symmetry-js" */),
  "component---src-pages-calculator-401-k-401-kanalysis-js": () => import("./../../../src/pages/calculator/401k/401kanalysis.js" /* webpackChunkName: "component---src-pages-calculator-401-k-401-kanalysis-js" */),
  "component---src-pages-calculator-check-information-js": () => import("./../../../src/pages/calculator/check-information.js" /* webpackChunkName: "component---src-pages-calculator-check-information-js" */),
  "component---src-pages-calculator-index-js": () => import("./../../../src/pages/calculator/index.js" /* webpackChunkName: "component---src-pages-calculator-index-js" */),
  "component---src-pages-calculator-printoptions-js": () => import("./../../../src/pages/calculator/printoptions.js" /* webpackChunkName: "component---src-pages-calculator-printoptions-js" */),
  "component---src-pages-calculator-w-4-js": () => import("./../../../src/pages/calculator/w4.js" /* webpackChunkName: "component---src-pages-calculator-w-4-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-payroll-resources-index-js": () => import("./../../../src/pages/payroll-resources/index.js" /* webpackChunkName: "component---src-pages-payroll-resources-index-js" */),
  "component---src-pages-private-label-calculators-js": () => import("./../../../src/pages/private-label-calculators.js" /* webpackChunkName: "component---src-pages-private-label-calculators-js" */),
  "component---src-pages-small-business-guide-download-payroll-calendar-js": () => import("./../../../src/pages/small-business-guide/download-payroll-calendar.js" /* webpackChunkName: "component---src-pages-small-business-guide-download-payroll-calendar-js" */),
  "component---src-pages-small-business-guide-index-js": () => import("./../../../src/pages/small-business-guide/index.js" /* webpackChunkName: "component---src-pages-small-business-guide-index-js" */),
  "component---src-pages-states-index-js": () => import("./../../../src/pages/states/index.js" /* webpackChunkName: "component---src-pages-states-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-calculator-js": () => import("./../../../src/templates/calculator.js" /* webpackChunkName: "component---src-templates-calculator-js" */),
  "component---src-templates-calculator-result-js": () => import("./../../../src/templates/calculator-result.js" /* webpackChunkName: "component---src-templates-calculator-result-js" */),
  "component---src-templates-calculator-state-js": () => import("./../../../src/templates/calculator-state.js" /* webpackChunkName: "component---src-templates-calculator-state-js" */),
  "component---src-templates-calculator-state-result-js": () => import("./../../../src/templates/calculator-state-result.js" /* webpackChunkName: "component---src-templates-calculator-state-result-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-payroll-state-historical-index-js": () => import("./../../../src/templates/payroll-state-historical-index.js" /* webpackChunkName: "component---src-templates-payroll-state-historical-index-js" */),
  "component---src-templates-payroll-state-historical-year-js": () => import("./../../../src/templates/payroll-state-historical-year.js" /* webpackChunkName: "component---src-templates-payroll-state-historical-year-js" */),
  "component---src-templates-payroll-state-js": () => import("./../../../src/templates/payroll-state.js" /* webpackChunkName: "component---src-templates-payroll-state-js" */),
  "component---src-templates-state-landing-js": () => import("./../../../src/templates/state-landing.js" /* webpackChunkName: "component---src-templates-state-landing-js" */)
}

